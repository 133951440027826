
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
/* HELPERS */
import { eventDate, eventDateTime } from '@/helpers/date'
@Component
export default class GenerateQR extends Vue {
  [x: string]: /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable @typescript-eslint/camelcase */
  any
  @State(({ appState }) => appState.cardStatus) cardStatus!: string
  @State(({ userState }) => userState.userInfo.fullname) fullname!: string
  @State(({ userState }) => userState.userInfo) userInfo!: any
  @State(({ userState }) => userState.qrData) qrData!: any
  @State(({ userState }) => userState.ticket) ticket!: any
  @State(({ userState }) => userState.product) product!: any
  @State(({ userState }) => userState.assistantFullname)
  assistantFullname!: any
  @State(({ userState }) => userState.assistantDNI) assistantDNI!: any
  @State(({ userState }) => userState.assistantGender) assistantGender!: any

  @Action changeMyCardStatus!: Function

  public isQRvalid = true
  public output = null

  get eventDateInit(): string {
    return eventDate(this.product.initial_date)
  }
  get eventDateEnd(): string {
    return eventDate(this.product.finish_date)
  }
  get eventDateTimeInit(): string {
    return eventDateTime(this.product.initial_date)
  }
  get eventDateTimeEnd(): string {
    return eventDateTime(this.product.finish_date)
  }
  get dateInitEvent(): string {
    return eventDateTime(this.product.initial_date)
  }
  get assistantGenderForHumans(): string {
    if (this.assistantGender == 'male') {
      return 'Masculino'
    } else if (this.assistantGender == 'female ') {
      return 'Femenino'
    } else {
      return 'Otro'
    }
  }

  protected async print() {
    const el = this.$refs.printcontent
    const options = {
      type: 'dataURL',
    }
    const printCanvas = await this.$html2canvas(el, options)
    const link = document.createElement('a')
    link.href = printCanvas
    link.download = 'QRticket.png'
    link.click()
  }

  protected capture() {
    const captureElement = document.querySelector('#capture')
    this.$html2canvas(captureElement)
      .then((canvas: any) => {
        canvas.style.display = 'none'
        document.body.appendChild(canvas)
        return canvas
      })
      .then((canvas: any) => {
        const image = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream')
        const a = document.createElement('a')
        a.setAttribute('download', 'my-image.png')
        a.setAttribute('href', image)
        a.click()
        canvas.remove()
      })
  }

  protected saveScreenshot(canvas: any) {
    const fileName = 'image'
    const link = document.createElement('a')
    link.download = fileName + '.png'
    canvas.toBlob(function(blob: any) {
      link.href = URL.createObjectURL(blob)
      link.click()
    })
  }

  get personalBG() {
    return {
      backgroundImage: 'url(' + this.ticket.cover_image + ')',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    }
  }
}
